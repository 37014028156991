// Generated by Framer (2a6858f)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-Ko8fj"

const variantClassNames = {Rlshc_B3v: "framer-v-ris4u1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Rlshc_B3v", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ris4u1", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Rlshc_B3v"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-z1ozqo"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Gk0StJ0M4-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 679 345\"><path d=\"M 5 340 L 339.5 5.5 L 674 340\" fill=\"transparent\" stroke-width=\"10\" stroke=\"rgb(170, 170, 170)\"></path></svg>"} svgContentId={11001759007} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ko8fj.framer-lj7gg5, .framer-Ko8fj .framer-lj7gg5 { display: block; }", ".framer-Ko8fj.framer-ris4u1 { height: 345px; overflow: hidden; position: relative; width: 679px; }", ".framer-Ko8fj .framer-z1ozqo { flex: none; height: 345px; left: calc(49.92636229749634% - 679px / 2); position: absolute; top: calc(49.85507246376814% - 345px / 2); width: 679px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 345
 * @framerIntrinsicWidth 679
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUOk95qFUj: React.ComponentType<Props> = withCSS(Component, css, "framer-Ko8fj") as typeof Component;
export default FramerUOk95qFUj;

FramerUOk95qFUj.displayName = "Icon/Chevron";

FramerUOk95qFUj.defaultProps = {height: 345, width: 679};

addFonts(FramerUOk95qFUj, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})